'use strict';

import 'angular';
import 'angular-cookies';
import 'angular-loading-bar';
import 'angular-material';
import 'angular-material-expansion-panel';
import 'angular-resource';
import 'angular-resizable';
import 'angular-sanitize';
import 'angular-ui-bootstrap';
import 'angular-ui-router';
import '@fortawesome/fontawesome-pro';
import '@fortawesome/fontawesome-svg-core';
import '@fortawesome/pro-duotone-svg-icons';
import '@fortawesome/pro-light-svg-icons';
import '@fortawesome/pro-regular-svg-icons';
import '@fortawesome/pro-solid-svg-icons';
import './constants';
import './build-shim';
import './app/deliveries/deliveries-module';
import './app/deliveries/deliveryDetail-module';
import './app/distributedassets/distributedAssets-module';
import './app/makelist/makeList-module';
import './app/common/notification/unsavedChanges-service';
import { AuthConstant } from './constants/auth.constant';
import './scss/main.scss';
import './asperaplugininstaller'; // eslint-disable-next-line

import { EndPointService } from './app/common/communication/endpoint-service'; // Declare app level module which depends on views, and components
// We shouldn't be requiring any shared pieces, since those are required at the component level

var dependencies = ['ui.bootstrap', 'ui.router', 'comcast-addelivery-constants', 'ngMaterial', 'material.components.expansionPanels', 'ngSanitize', 'angularResizable', 'comcast.login', 'comcast.dashboard', 'comcast.settings', 'comcast.settings.users', 'comcast.settings.destinations', 'comcast.settings.agencies', 'comcast.settings.invoices', 'comcast.settings.integrations', 'comcast.settings.xferclients', 'comcast.settings.defaultmediaprefersdetail', 'comcast.orders', 'comcast.orders.detail', 'comcast.distributedAssets', 'comcast.destinations', 'comcast.destinations.detail', 'comcast.spots', 'comcast.spots.detail', 'comcast.submit', 'comcast.lite', 'comcast.lite.detail', 'comcast.deliveries', 'comcast.deliveries.detail', 'comcast.makelist', 'comcast.common.notification', 'comcast.common.communication', 'angular-loading-bar'];
var defaultName = angular.module('comcast-addelivery-html5', dependencies).config(['$locationProvider', '$stateProvider', '$urlRouterProvider', '$httpProvider', 'addelivery.state.constants', '$mdThemingProvider', function ($locationProvider, $stateProvider, $urlRouterProvider, $httpProvider, stateConstants, $mdThemingProvider) {
  var authConstants = new AuthConstant();

  if (!$httpProvider.defaults.headers.common) {
    $httpProvider.defaults.headers.common = {};
  }

  $httpProvider.defaults.headers.common['If-Modified-Since'] = 'Thu, 9 Aug 1990 05:00:00 GMT';
  $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.common.Pragma = 'no-cache';
  $httpProvider.interceptors.push(["$q", "$location", function ($q, $location) {
    return {
      request: function request(config) {
        config.withCredentials = true;
        return config;
      },
      responseError: function responseError(rejection) {
        if ([401].indexOf(rejection.status) > -1) {
          try {
            localStorage.removeItem(authConstants.session.SESSION_DATA);
            localStorage.removeItem(authConstants.session.CURRENT_ACCOUNT_TYPE);
          } catch (e) {// Failed to clear storage because it was already cleared
          }

          $location.path('/login');
        }

        return $q.reject(rejection);
      }
    };
  }]); // Strap the JWT to each outgoing request

  $httpProvider.interceptors.push(["$q", "$window", function ($q, $window) {
    return {
      request: function request(config) {
        if ($window.localStorage[authConstants.session.ACCOUNT_HASH] !== undefined && $window.sessionStorage[authConstants.session.SESSION_DATA] !== undefined) {
          var accountHash = JSON.parse($window.localStorage[authConstants.session.ACCOUNT_HASH]);
          var sessionData = JSON.parse($window.sessionStorage[authConstants.session.SESSION_DATA]);
          var accountId = sessionData.accountId ? sessionData.accountId : 'NaN'; // eslint-disable-next-line

          config.headers['Authorization'] = 'Bearer ' + accountHash[accountId];
        }

        return config;
      },
      responseError: function responseError(rejection) {
        return $q.reject(rejection);
      }
    };
  }]);
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/');
  $stateProvider.state('main', {
    url: '/',
    views: {
      mainNav: stateConstants.mainNav,
      //subNav: stateConstants.subNav,
      leftNav: stateConstants.leftNav,
      content: {
        template: require('./app/dashboard/dashboard-template.html'),
        controller: 'DashboardController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AllOfThem'
    }
  }); // COLORS -- Modify the default theme to match our theme

  var cadBlueMap = $mdThemingProvider.extendPalette('indigo', {
    200: '#BFD9E7',
    500: '#0080C9',
    600: '#006ba8'
  }); // Register the new color palette map with the name <code>neonRed</code>

  $mdThemingProvider.definePalette('cadBlue', cadBlueMap); // Use that theme for the primary intentions

  $mdThemingProvider.theme('default').primaryPalette('cadBlue');
}]).config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
  cfpLoadingBarProvider.latencyThreshold = 500;
  cfpLoadingBarProvider.includeSpinner = false;
}]).run(['$rootScope', 'AppScopeService', '$location', '$http', '$state', '$window', '$mdDialog', 'loginService', function ($rootScope, appScope, $location, $http, $state, $window, $mdDialog, loginService) {
  var authConstants = new AuthConstant();
  $rootScope.$on('$stateChangeStart', function (event, next) {
    var authorized;
    $mdDialog.cancel(); // TODO: Add 'access' parameters to routes that need to be login controlled

    if (next && next.access !== undefined) {
      authorized = loginService.authorize(next.access.requiresLogin, next.access.requiredPermissions, next.access.permissionCheckType);
      appScope.setSavedLocation($location.url());

      if (authorized === authConstants.permission.authorized.loginRequired) {
        /*
         * This code is executed before a state change takes place
         * So we need to prevent it from continuing to take place if we
         * redirect the user
         * */
        event.preventDefault();
        $state.go('login', {
          successMsg: 'Please login to continue'
        });
      } else if (authorized === authConstants.permission.authorized.unauthorized) {
        $location.path('/search').replace();
      }
    }
  }); //isLoading is what locks the UI while data is loading.

  $rootScope.$on('cfpLoadingBar:started', function () {
    $rootScope.isLoading = true;
  });
  $rootScope.$on('cfpLoadingBar:completed', function () {
    $rootScope.isLoading = false;
  }); // Alright, let's do some videojs shenanigans to make it testable

  $window.videojs = videojs; // If we have problems with auth interceptors not evaluating in time, uncomment this
  // Once the user has logged in, sync the current URL
  // to the router:
  //$urlRouter.sync();
  // Configures $urlRouter's listener *after* your custom listener
  //$urlRouter.listen();
}]).name;
export { defaultName as default, dependencies };