// Polyfills
import 'zone.js';
import 'reflect-metadata';

import './app.module.ajs.js'

// Libraries for Angular/Upgrade
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { setAngularJSGlobal, UpgradeModule } from '@angular/upgrade/static';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import * as angular from 'angular';

// Angular Material Libraries
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';

// Upgrades Throwaway
import {
    httpModificationServiceProvider,
    submitManagerProvider,
    mdDialogServiceProvider,
    migrationManagerProvider
} from "./ajs-upgraded-providers";

import { IntegrationsViewModule } from './app/settings/integrationsdetail/modules/integrations-view.module';
import { SharedModule } from './app/common/modules/shared.module';
// Custom Providers/Services
import { WINDOW_PROVIDERS } from "./app/common/communication/window-provider";
import { EndPointService } from "./app/common/communication/endpoint-service";
import { StatusService } from "./app/common/communication/status-service";
import { UuidService } from "./app/common/communication/uuid-service";
import { UserAuthenticationFactory } from "./app/common/communication/userAuthentication-factory";
import { AppScopeService } from "./app/common/communication/appScope-service";
import { LoginService } from "./app/common/authentication/authentication-service";
import { LoggerService } from "./app/common/communication/logger.service";

import { BulkUploadService } from './app/common/communication/bulkUpload-service';
import { AgencyService } from './app/common/services/agency.service';
import { GoogleAnalyticsServiceService } from './app/common/communication/google-analytics-service.service';
import { GuidesService } from './app/common/services/guides.service';
// Interceptors
import { TokenInterceptor } from "./app/common/authentication/authentication-interceptor";

// Resources
import { DashboardResource } from "./app/common/communication/resources/dashboard-resource";
import { InvoiceResource } from "./app/common/communication/resources/invoice-resource";
import { WelcomeMessageResource } from "./app/common/communication/resources/welcomeMessage-resource";
import { AgencyResource } from "./app/common/communication/resources/agency-resource";
import { AdvertiserResource } from './app/common/communication/resources/advertiser-resource';
import { BrandResource } from './app/common/communication/resources/brand-resource-factory';
import { CampaignResource } from "./app/common/communication/resources/campaigns-resource-factory";
import { SpotResource } from './app/common/communication/resources/spot-resource';

// Constants
import { StatusConstant } from "./constants/status.constant";
import { DashboardConstant } from "./constants/dashboard.constant";
import { AuthConstant } from "./constants/auth.constant";
import { AppConstants } from './constants/app.constants';

// Components
import { CtsAudioSpotPlayerComponent } from "./app/common/components/cts-audio-spot-player/cts-audio-spot-player.component";
import { ExpandingGridHeaderComponent } from "./app/common/components/expanding-grid-header/expanding-grid-header.component";
import { CtsQuickSendIsciPanelComponent } from './app/common/components/cts-quick-send-isci-panel/cts-quick-send-isci-panel.component';
import { CtsQuickSendDestinationPanelComponent } from './app/common/components/cts-quick-send-destination-panel/cts-quick-send-destination-panel.component';
import { CtsMultiUploadProgressPanelComponent } from './app/common/components/cts-multi-upload-progress-panel/cts-multi-upload-progress-panel.component'
import { CtsWaitingPanelComponent } from './app/common/components/cts-waiting-panel/cts-waiting-panel.component';
import { CtsBulkUploadDialog } from './app/common/communication/cts-bulk-upload-dialog/cts-bulk-upload-dialog.component';
import { CtsBulkUploadInstructionsDialog } from './app/common/communication/cts-bulk-upload-instructions-dialog/cts-bulk-upload-instructions-dialog.component';
import { CtsBulkUploadOptionsDialog } from './app/common/communication/cts-bulk-upload-options-dialog/cts-bulk-upload-options-dialog.component';
import { CtsBulkUploadCompleteDialog } from './app/common/communication/cts-bulk-upload-complete-dialog/cts-bulk-upload-complete-dialog.component';
import { DeleteAgencyDialogComponent } from './app/settings/agencydetail/components/delete-agency-dialog/delete-agency-dialog.component';
import { UnableDeleteAgencyDialogComponent } from './app/settings/agencydetail/components/unable-delete-agency-dialog/unable-delete-agency-dialog.component';
import {  GuidesComponent } from './app/common/components/guides/guides.component';
// Directives
import { FileDropZoneDirective } from './app/common/directives/fileDropZone.directive';

// Pipes
import { TimeDurationPipe } from './app/common/pipes/time-duration.pipe';
import { TruncateFilenamePipe } from './app/common/pipes/filename-truncate.pipe'
import { SortByPipe } from './app/common/pipes/sort-by.pipe';


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatSelectModule,
        MatSnackBarModule,
        MatProgressBarModule,
        IntegrationsViewModule,
        SharedModule,
        MatButtonModule
    ],
    providers: [
        httpModificationServiceProvider,
        submitManagerProvider,
        mdDialogServiceProvider,
        migrationManagerProvider,
        WINDOW_PROVIDERS,
        EndPointService,
        StatusService,
        UuidService,
        GoogleAnalyticsServiceService,
        UserAuthenticationFactory,
        AppScopeService, // <- $rootScope replacement
        LoginService,
        LoggerService,
        BulkUploadService, // <- This should be removed when we move to all Angular components, because it has ProvidedIn:'root'
        AgencyService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: MatDialogRef,
            useValue: {},
        },
        DashboardResource,
        InvoiceResource,
        WelcomeMessageResource,
        AgencyResource,
        AdvertiserResource,
        BrandResource,
        CampaignResource,
        SpotResource,

        StatusConstant,
        DashboardConstant,
        AuthConstant,
        GuidesService,
        AppConstants
    ],
    entryComponents: [
        CtsAudioSpotPlayerComponent,
        ExpandingGridHeaderComponent,
        CtsQuickSendIsciPanelComponent,
        CtsQuickSendDestinationPanelComponent,
        CtsMultiUploadProgressPanelComponent,
        CtsWaitingPanelComponent,
        CtsBulkUploadDialog,
        CtsBulkUploadInstructionsDialog,
        CtsBulkUploadOptionsDialog,
        CtsBulkUploadCompleteDialog,
        GuidesComponent,
        DeleteAgencyDialogComponent,
        UnableDeleteAgencyDialogComponent
    ],
    declarations: [
        CtsAudioSpotPlayerComponent,
        ExpandingGridHeaderComponent,
        CtsQuickSendIsciPanelComponent,
        CtsQuickSendDestinationPanelComponent,
        CtsMultiUploadProgressPanelComponent,
        CtsWaitingPanelComponent,
        CtsBulkUploadDialog,
        CtsBulkUploadInstructionsDialog,
        CtsBulkUploadOptionsDialog,
        CtsBulkUploadCompleteDialog,
        TimeDurationPipe,
        TruncateFilenamePipe,
        FileDropZoneDirective,
        GuidesComponent,
        SortByPipe,
        DeleteAgencyDialogComponent,
        UnableDeleteAgencyDialogComponent
    ]
})
export class AppModule {
    constructor(private upgrade: UpgradeModule) { }
    ngDoBootstrap() {
        this.upgrade.bootstrap(document.body, ['comcast-addelivery-html5'], { dataSxCenterModals: true, sxCenterModals: true });
    }
}

// Establish AngularJS Dominance
setAngularJSGlobal(angular);
angular.element(document).ready(() => {
    platformBrowserDynamic().bootstrapModule(AppModule);
});
