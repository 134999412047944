// ---------------------------------------------------------------------------------
// ---------------------------- HttpModificationService ----------------------------
// ---------------------------------------------------------------------------------

declare var angular: angular.IAngularStatic;

export class HttpModificationService {
    static get $inject() {
        return ['$http'];
    }

    constructor(private $http:any) {
        Object.assign(this, {
            $http,
        });
    }

    //START FUNCTION BLOCK
    sessionKey(key:string|null = null, keyName:string) {
        if (key) {
            this.$http.defaults.headers.common[keyName] = key;
        } else {
            return this.$http.defaults.headers.common[keyName];
        }
    };

    deleteSessionKey(keyName:string) {
        delete this.$http.defaults.headers.common[keyName];
    }
}

angular.module('comcast.common.communication').service('httpModification', HttpModificationService);

export function HttpModificationServiceFactory(i: any) {
    return i.get('httpModification');
}

export const httpModificationServiceProvider = {
    provide: HttpModificationService,
    useFactory: HttpModificationServiceFactory,
    deps: ['$injector']
};

// ---------------------------------------------------------------------------------
// ---------------------------- HttpModificationService ----------------------------
// ---------------------------------------------------------------------------------

export class MdDialogService {
    static get $inject() {
        return ['$mdDialog'];
    }

    constructor(private $mdDialog:any) {
        Object.assign(this, {
            $mdDialog,
        });
    }

    //START FUNCTION BLOCK
    openDialog(dialog:any) {
        return this.$mdDialog.show(dialog);
    };
}

angular.module('comcast.common.communication').service('mdDialog', MdDialogService);

export function MdDialogServiceFactory(i: any) {
    return i.get('mdDialog');
}

export const mdDialogServiceProvider = {
    provide: MdDialogService,
    useFactory: MdDialogServiceFactory,
    deps: ['$injector']
};

// ---------------------------------------------------------------------------------
// --------------------------------- SubmitManager ---------------------------------
// ---------------------------------------------------------------------------------

import { SubmitManager } from "./app/common/communication/submitManager-service";

export function SubmitManagerFactory(i: any) {
    return i.get('submitManager');
}

export const submitManagerProvider = {
    provide: SubmitManager,
    useFactory: SubmitManagerFactory,
    deps: ['$injector']
};
// ---------------------------------------------------------------------------------
// --------------------------------- Migration Manager ---------------------------------
// ---------------------------------------------------------------------------------

import { MigrationManager } from "./app/common/communication/migrationManager-service";

export function MigrationManagerFactory(i: any) {
    return i.get('migrationManager');
}

export const migrationManagerProvider = {
    provide: MigrationManager,
    useFactory: MigrationManagerFactory,
    deps: ['$injector']
}; 